<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">News</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-headline">Four Save The World</div>
        <div class="pre-subhead caps">GHOSTBUSTERS SAVE THE WORLD FROM MASS PSYCHIC EVENT</div>
        <div class="pre-copy">
          <p>November 10th, 1984</p>
        </div>
      </div>
    </div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-2">
        <lazy-img
            class="pre-image border-0"
            src="/assets/images/four-save.png"
            alt=""
        />
      </div>
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>
            Some love ‘em! Some doubt ‘em! But one thing’s for sure: The
            Ghostbusters are here to stay.
          </p>
          <p>
            The Ghostbusters left New York City worse than they found it after the events of November 9th, but that’s a small price to pay for saving the world. Whilst ghosts and spirits wreaked havoc throughout the streets of New York, it transpired that a more dangerous battle was waging overheard. The famous quartet allegedly battled ‘Gozer’, a visiting deity hell bent on destroying humanity and, surprisingly, won. According to witness accounts, bright lights could be seen on the roof of the iconic Shandor Building as the showdown took place. Bizarrely the paranormal event culminated in an unusual visit from an oversized Stay Puft Marshmallow Man. According to Dr. Spengler of the Ghostbusters, Mr Puft was the chosen agent of destruction sent by Gozer.
          </p>
          <p>
            Whilst many are heralding the men as heroes, this newspaper included, some maintain that the Ghostbusters are charlatans, capitalizing on the public's superstitious gullibility. Walter Peck, an inspector for the Environmental Protection Agency, has called for a thorough investigation into the Ghostbusters' activities leading up to November 9th. Stay Puft marshmallows refused to comment on the Ghostbusters assertions. The clean-up continues.
          </p>
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-subhead caps">BREAKING:</div>
        <div class="pre-headline">Ghost Sightings Down 1000%</div>
      </div>
    </div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-2">
        <lazy-img
            class="pre-image border-0"
            src="/assets/images/news-2.png"
            alt=""
        />
      </div>

      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>
            If there’s something strange in your neighborhood, don’t bother calling the Ghostbusters. Chances are, it ain’t a ghost!
          </p>
          <p>
            A new report released by the New York Crime Commission, has revealed some promising findings. According to the report, ghost sightings are down 1000%. Furthermore, the NYPD have received zero verifiable reports of hauntings, possessions, or paranormal phenomena. Perhaps November 9th was a mass delusion after all.
          </p>
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-headline">Loss Adjustors Blast The Ghostbusters</div>
        <div class="pre-subhead caps">
          Investigations by multiple insurance firms as skepticism around the Ghostbusters grows.
        </div>
      </div>
    </div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>
            The vigilantes formerly known as the Ghostbusters are facing bankruptcy after their reckless and ill-planned defense of New York City caused irreparable destruction of property in downtown Manhattan, and millions of dollars in damage. Authorities conducted a raid on the Ghostbusters HQ and uncovered an armory of illegal, unlicensed, and hazardous equipment. It seems that civilians carrying nuclear reactors and blasting them all over the city might have some repercussions after all. The failing four claim their actions were necessary, citing the damage as “unfortunate but better than the alternative". A likely story. Many in New York’s business district are calling for the Ghostbusters to be shut down before they stage another one of their publicity stunts.
          </p>
        </div>
      </div>

      <div class="pre-col-1-2">
        <lazy-img
            class="pre-image border-0"
            src="/assets/images/news-3.png"
            alt=""
        />
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-subhead caps">SPENGLER DOES IT AGAIN</div>
        <div class="pre-headline">
          GROUNDBREAKING GADGET “SPIRIT NET™” WILL CHANGE GLOBAL COMMUNICATION
          FOREVER
        </div>
      </div>
    </div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-2">
        <lazy-img
            class="pre-image border-0"
            src="/assets/images/news-4.png"
            alt=""
        />
      </div>

      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>
            Dr Egon Spengler of Ghostbusters fame has announced the launch of a groundbreaking new gadget. Spirit Net™ is a psycho-kinetically powered two-way tele-vid conferencing helmet that allows video communication from anywhere in the world from one headset to a network of individuals. It’s like a telephone call, but with video. Dr Spengler has spent months connecting up key locations to Spirit Net™, testing connectivity and resolving technical issues. Dr Spengler was unavailable for comment when asked. However, one Janine Melnitz, self-professed tour guide of a new Ghostbusting academy responded: “Egon likes making things. He was very clear that we needed a system that would allow us to record psychokinetic energy remotely. Our students, sorry, our apprentices will use the ‘Net to patch into lessons at the Paranormal Institute. That way, we hope to avoid injuries. He said that would be bad.”

          </p>
          <p>
            Walter Peck, an inspector for the Environmental Protection Agency and newly appointed lead of the Paranormal Contracts Oversights Commission, has launched a lawsuit in an attempt to block the Spirit Net™ launch. Peck said: “This preposterous gadget has not yet been approved nor licensed for use. Dr Spengler has failed to provide the appropriate paperwork and it will be shut down forthwith! I will not be made a fool of again.”
          </p>
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-headline">Ghostbusters Return To Academia</div>
        <lazy-img
            class="pre-image border-0"
            src="/assets/images/news-5.png"
            alt=""
        />
        <div class="pre-pad"></div>
        <div class="pre-subhead caps">
          The famous four have founded the Paranormal Institute to teach budding young ghost enthusiasts how to become a verified Ghostbuster.
        </div>
        <div class="pre-copy">
          <p>
            The Ghostbusters, once disgraced from Columbia University, are steaming ahead with the opening of their apprentice training program and new venture, the Paranormal Institute, and according to Institute spokesperson Janine Melnitz, applications from eager students have been flooding in.
          </p>
          <p>
            “I am thrilled to announce that the Paranormal Institute is now open. As a highly reputable and well established organization, we offer the best education in paranormal studies around. Smile to camera. [sic] Sign up today and get your friends to sign up too.” - Janine Melnitz
          </p>
          <p>
            The first students have now signed up to participate and are conducting their own field studies. Many are reporting new sightings, fueling public paranoia that we could see a return of ghouls and even poltergeists to the streets of New York City.
          </p>
          <p>
            Dr Ray Stantz of the Ghostbusters outlined what students can expect.
          </p>
          <p>
            “We will be training students to recognize, capture and contain phenomena from full-torso, free roaming and vaporous forms right up to completely manifested malevolent deities. We wanna really get into the detail. The science of the paranormal.” - Dr Ray Stantz
          </p>
          <p>What could this mean for New York City? Only time will tell.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "News",
  data() {
    return {
      news: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 800px;
}
.description {
  padding-bottom: 32px;
  border-bottom: 0.8px solid #6bca76;
  margin-bottom: 32px;
}
.pre-image {
  max-width: 100%;
}
</style>
